import React, { useState } from "react"
import client from "../utils/apollo-client"
import { ApolloProvider, gql, useMutation } from "@apollo/client"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurServices from "../components/Index/OurServices"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import WhyChooseUs from "../components/SEOAgency/WhyChooseUs"

/*const LOGIN_ANONYMOUS = gql`
mutation {
  login(input: { identifier: "walid.ali@ipalm.tn", password: "@n0NYm052021" }) {
    jwt
  }
}
`;*/

const Home = () => {
  /*let token = localStorage.getItem('token');


  if (token === null || token === undefined || token === "") {
    const [onLoginSuccess] = useMutation(LOGIN_ANONYMOUS, { client: client });
    onLoginSuccess().then((value) => {
      localStorage.setItem('token', value.data?.login.jwt);

    }).catch((err) => {
      console.log(err);
    });

  }*/

  return (
    <ApolloProvider client={client}>
      <Layout>
        <Navbar />
        <Banner />
        <WhyChooseUs />
        <OurServices />
        <ProjectStartArea />
        <Footer />
      </Layout>
    </ApolloProvider>
  )
}

export default Home