import React from "react"
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const httpLink = new HttpLink({
  uri: "http://162.19.67.2:1337/graphql/",
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
   headers: {
     "Access-Control-Allow-Origin": "*",
     "Content-Type": "application/json",
     "Access-Control-Request-Method": "POST",
   },
});

export default client