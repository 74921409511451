import React from "react";
import businessAnalysis from "../../assets/images/process/process7.png";
import digitalTransformation from "../../assets/images/our-mission/our-mission1.png";
import artificialIntelligence from "../../assets/images/main-banner/banner-two/banner-two-main-img.png";
import digitalLiteracy from "../../assets/images/seo-analysis.png";
import starIcon from "../../assets/images/star-icon.png";
import { useIntl } from "react-intl";

const OurServices = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      {/* Service Right Image Style - Business Analysis*/}
      <div className="our-mission-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" />{" "}
                    {intl.formatMessage({ id: "services" })}{" "}
                  </span>

                  <h2>{intl.formatMessage({ id: "businessanalysis" })} </h2>
                  <p>{intl.formatMessage({ id: "businessanalysismsg" })} </p>

                  <ul className="our-mission-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep1" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep2" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep3" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep4" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep5" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "businessanlysisstep6" })}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img src={businessAnalysis} alt="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Right Image Style */}
      {/* Service Left Image Style - Digital Transformation */}
      <div className="about-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={digitalTransformation} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" />{" "}
                    {intl.formatMessage({ id: "services" })}{" "}
                  </span>

                  <h2>
                    {intl.formatMessage({ id: "digitaltransformation" })}{" "}
                  </h2>
                  <p>
                    {intl.formatMessage({ id: "digitaltransformationmsg" })}{" "}
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationanalyse",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationdignose",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationroadmaps",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationstrategy",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationstudies",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "digitaltransformationsolution",
                      })}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}
      {/* Service Right Image Style - Artificial Intelligence*/}
      <div className="our-mission-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" />{" "}
                    {intl.formatMessage({ id: "services" })}{" "}
                  </span>

                  <h2>
                    {intl.formatMessage({ id: "artificialintelligence" })}{" "}
                  </h2>
                  <p>
                    {intl.formatMessage({ id: "artificialintelligencemsg" })}{" "}
                  </p>

                  <ul className="our-mission-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligencestrategy",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligencedata",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligenceimpact",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligenceethics",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligenceplan",
                      })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({
                        id: "artificialintelligencepartner",
                      })}{" "}
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img src={artificialIntelligence} alt="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Right Image Style */}
      {/* Service Left Image Style - Digital Literacy*/}
      <div className="about-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={digitalLiteracy} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" />{" "}
                    {intl.formatMessage({ id: "services" })}{" "}
                  </span>

                  <h2>
                    {intl.formatMessage({ id: "digitalliteracy" })}{" "}
                  </h2>
                  <p>
                    {intl.formatMessage({ id: "digitalliteracymsg" })}{" "}
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "digitalliteracyskills" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "digitalliteracycustomized" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "digitalliteracydata" })}{" "}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      {intl.formatMessage({ id: "digitalliteracymedia" })}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}
    </React.Fragment>
  );
};

export default OurServices;
